import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { EllipsisPipe } from './shared/pipes/ellipsis/ellipsis-pipe';
import { VnToastsModule } from './components/vn-toasts/vn-toasts.module';
import { DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    EllipsisPipe
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbModule,
    AppRoutingModule,
    DecimalPipe,
    VnToastsModule
  ],
  providers: [
    CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
