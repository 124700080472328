import { Injectable } from '@angular/core';
import { catchError, first } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { BaseURLParams } from 'src/app/shared/interfaces/base-params';
import { ApiBaseService } from './api-base.service';
import { CookieService } from 'ngx-cookie-service';
import { Constants } from 'src/app/config/constants';
import jwt_decode, {JwtPayload} from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService extends ApiBaseService {

  private get _token(): string {
    return this._cookieService.get('token') || '';
  }

  constructor(
    private _http: HttpClient,
    protected _cookieService: CookieService,
  ) {
    super(
      _cookieService,
    );
  }


  /**
   * @description Error catching function for this whole service
   * @private
   * @param {(HttpErrorResponse | any)} res
   * @return {*}  {Observable<any>}
   * @memberof ApiHttpService
   */
  public handleError(res: HttpErrorResponse | any): Observable<any> {
    if (res !== undefined && res !== null) {
      let err = res;
      if (res.error !== undefined) err = res.error;
      if (res.error === undefined && res.body !== undefined) err = res.body;
      if (res.error === undefined && res.body !== undefined && res.body.error !== undefined) err = res.body.error;

      //console.error(err);
      return throwError(err || 'Server error');
    }
    // Token expired
    if (res.error.errorCode == 11) {
      // TODO: if there is a refresh token, try to acquired a new token with it

    }
    return of(null);
  }

  /**
   * @description Constructs a GET request that interprets the body as an ArrayBuffer and returns the response in an ArrayBuffer.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>}
   * @memberof ApiHttpService
   */
  public get(params: BaseURLParams): Observable<any> {
    let url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    return this._http.get(url, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }

  /**
   * @description Constructs a POST request that interprets the body as an ArrayBuffer and returns an ArrayBuffer.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>} An Observable of the response, with the response body as an ArrayBuffer
   * @memberof ApiHttpService
   */
  public post(params: BaseURLParams): Observable<any> {
    let url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    return this._http.post(url, params.body, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }

  /**
   * @description Constructs a PUT request that interprets the body as an ArrayBuffer and returns the response as an ArrayBuffer.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>}
   * @memberof ApiHttpService
   */
  public put(params: BaseURLParams): Observable<any> {
    let url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    return this._http.put(url, params.body, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }

  /**
   * @description Constructs a DELETE request that interprets the body as an ArrayBuffer and returns the response as a boolean.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>} An Observable of the response, with the response body as an ArrayBuffer
   * @memberof ApiHttpService
   */
  public delete(params: BaseURLParams): Observable<boolean> {
    let url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    return this._http.delete(url, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }

  ///      ^                            ^
  ///     / \                          / \
  ///    / | \         NOTICE         / | \
  ///   /  o  \                      /  o  \
  ///  /_______\                    /_______\
  ///
  /// The implementation of authorization headers with an interceptor was used and was working until
  /// uploading of an image was necessary. The interceptor had no issues sending the data to the .net API,
  /// however, the Thingsboard API, which is based off JAVA, was having issues with it and not allowing
  /// the uploading of files. All kinds of changes were tried, and even an issue on stackoverflow similar to
  /// the one described was found, but with no answers ->
  /// https://stackoverflow.com/questions/59144968/angular-2-upload-file-interceptor-issue)
  /// As such, the dealing with headers and auth headers was decided to be implemented in this wrapper
  /// so the headers can be created or modified even before the requests begging.
  ///
  private _setUpHeaders(params: BaseURLParams) {
    if (params.options == undefined) params.options = {};
    if (params.options.headers == undefined) params.options.headers = {};
    let requestHeaders = params.options.headers;
    let returnHeaders = {};
    if (params.url.includes(Constants.API_VSBLTY_URL)) {
      returnHeaders['Accept'] = requestHeaders['Accept'] || '*/*';
      // 'Content-Type': 'application/json',
      if (this._token != '') {
        returnHeaders['Authorization'] = `Bearer ${this._token}`;
      }

    } else {
      let accept = requestHeaders['Accept'];
      accept = accept === null ? 'application/json' : accept;
      if (accept != null) {
        returnHeaders['Accept'] = accept;
      };

      //'multipart/form-data'
      let contentType = requestHeaders['Content-Type'];
      contentType = contentType === 'multipart/form-data' ? null : contentType != null ? contentType : 'application/json';
      if (contentType != null) {
        returnHeaders['Content-Type'] = contentType == '' ? undefined : contentType;
      }

      // add authorization header with basic auth credentials if available
      if (this._token != '') {
        returnHeaders['X-Authorization'] = `Bearer ${this._token}`;
      }
    }
    params.options.headers = returnHeaders;
  }

  /**
   * @description Constructs a POST request that interprets the body as an ArrayBuffer and returns an ArrayBuffer.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>} An Observable of the response, with the response body as an ArrayBuffer
   * @memberof ApiHttpService
   */
   public postMedia(params: BaseURLParams): Observable<any> {
    let url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    params.options.reportProgress = true;
    params.options.observe = "events";
    return this._http.post(url, params.body, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }

    /**
   * @description Constructs a POST request that interprets the body as an ArrayBuffer and returns a Blob.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>} An Observable of the response, with the response body as a Blob
   * @memberof ApiHttpService
   */
    public postCsvAndReceive(params: BaseURLParams): Observable<any> {
      let url = this._createUrl(params.url, params.urlArguments);
      this._setUpHeaders(params);
      params.options.reportProgress = true;
      params.options.observe = "body";
      params.options.responseType = "blob";
      return this._http.post(url, params.body, params.options)
        .pipe(
          first(),
          catchError(this.handleError)
        );
    }

  /**
   * @description Constructs a PUT request that interprets the body as an ArrayBuffer and returns an ArrayBuffer.
   * @param {BaseURLParams} params
   * @return {*}  {Observable<any>} An Observable of the response, with the response body as an ArrayBuffer
   * @memberof ApiHttpService
   */
  putMedia(params: BaseURLParams): Observable<any> {
    const url = this._createUrl(params.url, params.urlArguments);
    this._setUpHeaders(params);
    params.options.reportProgress = true;
    params.options.observe = 'events';
    return this._http.post(url, params.body, params.options)
      .pipe(
        first(),
        catchError(this.handleError)
      );
  }
}
