import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { VnToastService } from './components/vn-toasts/vn-toasts.service';
import { UtilsService } from './services/utils';
import { filter } from "rxjs/operators";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(document:mousedown)': 'onMouseDown($event)',
        '(document:keydown)': 'onKeyDown($event)',
        //'(window:scrollY)': 'onScrollY($event)',
        '(window:resize)': 'onResize($event)'
    },
    standalone: false
})
export class AppComponent {
  constructor(
    private _utilsService: UtilsService,
    router: Router
  ) { 
    router.events
			.pipe(
				filter(
					( event: NavigationEvent ) => {
						return( event instanceof NavigationStart );
					}
				)
			)
			.subscribe(
				( event: NavigationStart ) => {
					// console.log( "trigger:", event.navigationTrigger );

          // close modals that remain open on back/forward navigation
          if(document.body.className.indexOf('modal-open') !== -1){
            let modalCloseButton: HTMLElement = document.querySelector('.modal.show .btn-close') as HTMLElement;
            if(modalCloseButton != undefined){
              modalCloseButton.click();
            }
          }
				}
			)
		;
  }

  onResize(evt: Event) {
    this._utilsService.globalListeners.window.resize.next(evt);
  }

  onMouseDown(evt: MouseEvent) {
    // left click
    if (evt.button === 0) {
      this._utilsService.globalListeners.click.left.next(evt);
    }
    // middle click
    if (evt.button === 1) {
      this._utilsService.globalListeners.click.middle.next(evt);
    }
    // right click
    if (evt.button === 2) {
      this._utilsService.globalListeners.click.right.next(evt);
    }
    this._utilsService.globalListeners.click.any.next(evt);
  }

  onKeyDown(evt: KeyboardEvent) {
    // console.log(evt);
    if (evt.ctrlKey || evt.altKey || evt.shiftKey) {
      if (evt.ctrlKey && evt.shiftKey && evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.ctrlShiftAlt[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.ctrlShiftAlt.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.ctrlShiftAlt.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.ctrlShiftAlt.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.ctrlShiftAlt.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.ctrlShiftAlt.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.ctrlShiftAlt.numpad[9].next(evt);
      }
      if (evt.ctrlKey && evt.shiftKey && !evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.ctrlShift[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.ctrlShift.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.ctrlShift.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.ctrlShift.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.ctrlShift.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.ctrlShift.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.ctrlShift.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.ctrlShift.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.ctrlShift.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.ctrlShift.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.ctrlShift.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.ctrlShift.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.ctrlShift.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.ctrlShift.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.ctrlShift.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.ctrlShift.numpad[9].next(evt);
      }
      if (evt.ctrlKey && !evt.shiftKey && evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.ctrlAlt[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.ctrlAlt.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.ctrlAlt.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.ctrlAlt.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.ctrlAlt.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.ctrlAlt.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.ctrlAlt.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.ctrlAlt.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.ctrlAlt.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.ctrlAlt.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.ctrlAlt.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.ctrlAlt.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.ctrlAlt.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.ctrlAlt.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.ctrlAlt.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.ctrlAlt.numpad[9].next(evt);
      }
      if (evt.ctrlKey && !evt.shiftKey && !evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.ctrl[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.ctrl.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.ctrl.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.ctrl.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.ctrl.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.ctrl.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.ctrl.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.ctrl.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.ctrl.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.ctrl.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.ctrl.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.ctrl.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.ctrl.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.ctrl.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.ctrl.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.ctrl.numpad[9].next(evt);
      }
      if (!evt.ctrlKey && evt.shiftKey && evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.altShift[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.altShift.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.altShift.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.altShift.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.altShift.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.altShift.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.altShift.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.altShift.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.altShift.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.altShift.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.altShift.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.altShift.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.altShift.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.altShift.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.altShift.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.altShift.numpad[9].next(evt);
      }
      if (!evt.ctrlKey && evt.shiftKey && !evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.shift[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.shift.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.shift.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.shift.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.shift.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.shift.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.shift.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.shift.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.shift.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.shift.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.shift.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.shift.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.shift.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.shift.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.shift.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.shift.numpad[9].next(evt);
      }
      if (!evt.ctrlKey && !evt.shiftKey && evt.altKey) {
        if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key.alt[evt.key.toLocaleLowerCase()].next(evt);
        if (evt.key === 'Delete') this._utilsService.globalListeners.key.alt.delete.next(evt);
        if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.alt.arrow.up.next(evt);
        if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.alt.arrow.down.next(evt);
        if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.alt.arrow.left.next(evt);
        if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.alt.arrow.right.next(evt);
        if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.alt.numpad[0].next(evt);
        if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.alt.numpad[1].next(evt);
        if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.alt.numpad[2].next(evt);
        if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.alt.numpad[3].next(evt);
        if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.alt.numpad[4].next(evt);
        if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.alt.numpad[5].next(evt);
        if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.alt.numpad[6].next(evt);
        if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.alt.numpad[7].next(evt);
        if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.alt.numpad[8].next(evt);
        if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.alt.numpad[9].next(evt);
      }
    } else {
      if (this._utilsService.isCharacterALetter(evt.key)) this._utilsService.globalListeners.key[evt.key.toLocaleLowerCase()].next(evt);
      if (evt.key === 'Delete') this._utilsService.globalListeners.key.delete.next(evt);
      if (evt.key === 'Insert') this._utilsService.globalListeners.key.insert.next(evt);
      if (evt.key === 'Escape') this._utilsService.globalListeners.key.escape.next(evt);
      if (evt.key === 'ArrowUp') this._utilsService.globalListeners.key.arrow.up.next(evt);
      if (evt.key === 'ArrowDown') this._utilsService.globalListeners.key.arrow.down.next(evt);
      if (evt.key === 'ArrowLeft') this._utilsService.globalListeners.key.arrow.left.next(evt);
      if (evt.key === 'ArrowRight') this._utilsService.globalListeners.key.arrow.right.next(evt);
      if (evt.code === 'Numpad0') this._utilsService.globalListeners.key.numpad[0].next(evt);
      if (evt.code === 'Numpad1') this._utilsService.globalListeners.key.numpad[1].next(evt);
      if (evt.code === 'Numpad2') this._utilsService.globalListeners.key.numpad[2].next(evt);
      if (evt.code === 'Numpad3') this._utilsService.globalListeners.key.numpad[3].next(evt);
      if (evt.code === 'Numpad4') this._utilsService.globalListeners.key.numpad[4].next(evt);
      if (evt.code === 'Numpad5') this._utilsService.globalListeners.key.numpad[5].next(evt);
      if (evt.code === 'Numpad6') this._utilsService.globalListeners.key.numpad[6].next(evt);
      if (evt.code === 'Numpad7') this._utilsService.globalListeners.key.numpad[7].next(evt);
      if (evt.code === 'Numpad8') this._utilsService.globalListeners.key.numpad[8].next(evt);
      if (evt.code === 'Numpad9') this._utilsService.globalListeners.key.numpad[9].next(evt);
    }
  }
}
export class BaseComponent {
  private _instanceCount: number;
  constructor(
    protected _router: Router,
    protected _ref: ChangeDetectorRef,
    protected _toastService?: VnToastService,
  ) {
    this._instanceCount = (new Date().getTime() * 100000) + Math.floor(Math.random() * 100000);
  }
  getInstanceId(id: string): string {
    // if id starts with #, then we have to return the id with it at the beginning
    if (id[0] == '#') {
      return `#${this.constructor.name.toLowerCase()}_${id.split('#')[1]}_${this._instanceCount}`;
    } else {
      return `${this.constructor.name.toLowerCase()}_${id}_${this._instanceCount}`;
    }
  }

  protected reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
    let to = setTimeout(() => {
      this._ref.detectChanges();
      clearTimeout(to);
    }, 500);
  }

  protected reloadComponent() {
    let currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
    let to = setTimeout(() => {
      this._ref.detectChanges();
      clearTimeout(to);
    }, 500);
  }

  protected defaultServerResponseHandler(message: string, success?: boolean, closeModalButton?: HTMLButtonElement): void
  protected defaultServerResponseHandler(message: { error: string }, success?: boolean, closeModalButton?: HTMLButtonElement): void;
  protected defaultServerResponseHandler(message: { error: { message: string } }, success?: boolean, closeModalButton?: HTMLButtonElement): void;
  protected defaultServerResponseHandler(message: string | { error: string } | { error: { message: string } }, success?: boolean, closeModalButton?: HTMLButtonElement): void {
    let text = '';
    if (typeof message === 'string') {
      text = message;
    }
    if ((<any>message).error != undefined && typeof (<any>message).error === 'string') {
      text = (<any>message).error;
    }

    if ((<any>message).error != undefined && (<any>message).error.message != undefined && typeof (<any>message).error.message === 'string') {
      text = (<any>message).error.message;
    }
    //closing the modal if any
    closeModalButton?.click();
    if (success) {
      this._toastService.success(text);
      this.reloadCurrentRoute();
    } else {
      this._toastService.error(text);
    }
  }
}
