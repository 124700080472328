import { Component, OnInit } from '@angular/core';
import { VnToastService } from './vn-toasts.service';

@Component({
    selector: 'vn-toasts',
    templateUrl: './vn-toasts.component.html',
    styleUrls: ['./vn-toasts.component.scss'],
    standalone: false
})
export class VnToastsComponent implements OnInit {

  constructor(
    public toastService: VnToastService,
  ) { }

  ngOnInit(): void {
  }

}

export type toastType = 'default' | 'success' | 'info' | 'warn' | 'error';
